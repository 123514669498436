import * as React from 'react'

import { cn } from '#app/utils/misc.tsx'
import { Icon } from './icon'
import { type IconName } from '@/icon-name'

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	startIcon?: {
		name: IconName
		className?: string
	}
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, startIcon, ...props }, ref) => {
		return (
			<div className="relative flex w-full flex-1 flex-row items-center">
				{startIcon ? (
					<Icon
						name={startIcon.name}
						className={startIcon.className ?? 'absolute left-3 text-gray-400'}
					/>
				) : null}

				<input
					type={type}
					className={cn(
						'flex h-10 w-full flex-1 rounded-md border border-input bg-background px-3 py-2 text-sm placeholder-gray-400 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid',
						startIcon ? 'pl-9' : '',
						className,
					)}
					ref={ref}
					{...props}
				/>
			</div>
		)
	},
)
Input.displayName = 'Input'

export { Input }
